import React from 'react'
import cx from 'classnames'

import { Text, DynamicImage, List, Badge } from '../../atoms'
import * as styles from './project-panel.module.scss'

type Props = {
  id: string
  title: string
  kind: string
  imgName: string
  description: string
  keywords: string[]
  experiences: string[]
  className?: string
  color?: string
}

export const ProjectPanel: React.FC<Props> = (props) => {
  const {
    id,
    title,
    kind,
    imgName,
    description,
    keywords,
    experiences,
    className,
    color = 'var(--primary-color)',
  } = props

  return (
    <div id={id} className={cx(styles.projectPanel, className)}>
      <Text as="h3" className={styles.title} size="l">
        {title}
      </Text>
      <p className={styles.kind}>{kind}</p>
      <div className={styles.mainContainer}>
        <div className={styles.leftContainer}>
          <DynamicImage filename={imgName} />
          <p className={styles.description}>{description}</p>
        </div>
        <div className={styles.rightContainer}>
          <Text as="h4" size="l">
            技術スタック
          </Text>
          <div className={styles.keywordsContainer}>
            {keywords.map((keyword) => (
              <Badge
                key={keyword}
                text={keyword}
                style={{ background: color, borderColor: color }}
              />
            ))}
          </div>
          <Text as="h4" size="l">
            業務内容
          </Text>
          <div className={styles.experiencesContainer}>
            {experiences.map((experience) => (
              <List key={experience} text={experience} className={styles.list} />
            ))}
          </div>
        </div>
        {/* <div className={styles.bar}></div> */}
      </div>
    </div>
  )
}

export default ProjectPanel
