import React from 'react'
import { graphql, PageProps } from 'gatsby'

import { SEO, Layout, TopImageBox } from '../components/organisms/common'
import { Projects } from '../components/organisms/work'

type Props = {
  data: Queries.WorksQuery
} & PageProps

const Work: React.FC<Props> = (props) => {
  const { data } = props

  return (
    <Layout path="/work/">
      <TopImageBox filename="work.jpeg" alt="work top image" title="Work" />
      <Projects data={data} />
    </Layout>
  )
}

export default Work

export const Head = () => <SEO />

export const pageQuery = graphql`
  query Works {
    allContentfulWork(sort: { fields: priority }) {
      edges {
        node {
          id
          title
          kind
          image {
            id
          }
          keywords
          experiences
          description {
            description
          }
        }
      }
    }
  }
`
