import React from 'react'
import cx from 'classnames'

import { Text } from '../../atoms'
import { ProjectPanel } from './project-panel'
import * as styles from './projects.module.scss'

type Props = {
  data: Queries.WorksQuery
}

const Projects: React.FC<Props> = (props) => {
  const { data } = props
  const works = data.allContentfulWork.edges

  return (
    <section className={styles.projectsSection}>
      <Text as="h2" textType="subTitle">
        PROJECTS
      </Text>
      {works.map((work, i) => (
        <div key={work.node.id} className={cx(styles.panelWrapper, i % 2 === 1 && styles.subColor)}>
          <div className="container">
            <ProjectPanel
              id={work.node.id || ''}
              title={work.node.title || ''}
              kind={work.node.kind || ''}
              imgName={work.node.image?.id || 'no-img.jpg'}
              description={work.node.description?.description || ''}
              keywords={work.node.keywords as string[]}
              experiences={work.node.experiences as string[]}
              color={i % 2 === 1 ? 'var(--second-color)' : 'var(--primary-color)'}
            />
          </div>
        </div>
      ))}
    </section>
  )
}

export default Projects
