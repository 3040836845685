// extracted by mini-css-extract-plugin
export var bar = "project-panel-module--bar--n9GmC";
export var description = "project-panel-module--description---u+Ok";
export var experiencesContainer = "project-panel-module--experiencesContainer--YV9ln";
export var keywordsContainer = "project-panel-module--keywordsContainer--DgUOD";
export var kind = "project-panel-module--kind--VTiHc";
export var leftContainer = "project-panel-module--leftContainer--CdgL6";
export var list = "project-panel-module--list--9lfeE";
export var mainContainer = "project-panel-module--mainContainer--YmPZA";
export var projectPanel = "project-panel-module--projectPanel--nXpOn";
export var rightContainer = "project-panel-module--rightContainer--l7vIB";
export var title = "project-panel-module--title--OiqWm";